import { styled } from '@linaria/react'
import type { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from '../icon'

const Container = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const Tab = styled.button`
  flex: 1;
  height: 40px;
  font-family: 'Poppins';
  font-weight: 500;
  text-transform: uppercase;
  font-size: 16px;
  color: #999;
  transition: all 0.2s ease-in-out;
  .theme-dark & {
    background-color: #1a1a1c;
  }
  .theme-light & {
    background-color: #e0e0ec;
  }
  svg {
    margin-right: 12px;
    transition: all 0.2s ease-in-out;
    fill: #999;
  }
  &.active {
    color: var(--txt);
    background-color: transparent;
    svg {
      fill: var(--txt);
    }
  }
  @media (min-width: 1200px) {
    font-size: 18px;
    height: 50px;
  }
`

const Close = styled.button`
  color: var(--highlight);
  padding: 8px 16px;
  text-decoration: underline;
  display: flex;
  background-color: var(--highlight);
  svg {
    fill: #fff;
    width: 14px;
    height: 14px;
  }
`

interface TabsProps {
  activeTab: number
  setActiveTab: Dispatch<SetStateAction<number>>
  hide: () => void
}

const Tabs = ({ activeTab, setActiveTab, hide }: TabsProps) => {
  const { t } = useTranslation('walletManagement')

  return (
    <Container>
      <Tab
        className={`flx-ctr ${activeTab === 0 ? 'active' : ''}`}
        onClick={() => setActiveTab(0)}>
        {t('deposit')}
      </Tab>
      <Tab
        className={`flx-ctr ${activeTab === 1 ? 'active' : ''}`}
        onClick={() => setActiveTab(1)}>
        {t('withdrawal')}
      </Tab>
      <Close className='flx-ctr' onClick={hide}>
        <Icon id='close' width={12} height={12} />
      </Close>
    </Container>
  )
}

export default Tabs
