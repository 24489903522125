import { useModal } from '@ebay/nice-modal-react'
import { useNavigate } from '@tanstack/router'
import type { FC} from 'react'
import { useEffect } from 'react'

import { Loading } from '../components/common'
import LogInModal from '../components/common/log-in-modal'
import { useBoundStore } from '../store/store'

function protectRoute(WrappedComponent: FC, isProtected?: boolean) {
  const ProtectRoute = (props: Record<string, unknown>) => {
    const isUserLoading = useBoundStore(state => state.user.isLoading)
    const username = useBoundStore(state => state.user.username)
    const navigate = useNavigate()
    const logInModal = useModal(LogInModal)

    useEffect(() => {
      if (isUserLoading) return
      if (!username && isProtected) {
        navigate({ to: '/', search: { redirectUrl: location.pathname } })
        logInModal.show()
      }
    }, [isUserLoading])

    useEffect(() => {
      document.body.classList.remove('noscroll')
    }, [])

    if (isUserLoading) return <Loading solidBg />

    return <WrappedComponent {...props} />
  }

  return ProtectRoute
}

export default protectRoute
