import { lazy,Route } from '@tanstack/router'

import { referralRoute } from '.'

const Referral = lazy(() => import('../../../pages/profile/referral/referral'))

export const referralIndexRoute = new Route({
  getParentRoute: () => referralRoute,
  path: '/',
  component: Referral,
})
