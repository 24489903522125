import { lazy,Route } from '@tanstack/router'

import { affiliateRoute } from '.'

const MemberList = lazy(
  () => import('../../../pages/profile/affiliate/member-list'),
)

export const memberListRoute = new Route({
  getParentRoute: () => affiliateRoute,
  path: 'member-list',
  component: MemberList,
})
