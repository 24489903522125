import { useBoundStore } from '../store/store'
import type { AllGamesKey, Game } from '../types/game'
import { api } from '../utils/api-client'

const useGames = () => {
  const username = useBoundStore(state => state.user.username)
  const allGames = useBoundStore(state => state.casino.allGames)
  const categories = useBoundStore(state => state.casino.summary?.categories)
  const setCategoryGames = useBoundStore(state => state.casino.setCategoryGames)
  const loadMoreLoading = useBoundStore(state => state.casino.loadMoreLoading)
  const setLoadMoreLoading = useBoundStore(state => state.casino.setLoadMoreLoading)

  const loadMoreGames = async (
    categoryKey: AllGamesKey,
    platform: string,
    fresh: boolean,
  ) => {
    if (categoryKey === 'favorites') return

    const totalCount = Number(
      platform
        ? categories?.[categoryKey]?.providers?.[platform]
        : categories?.[categoryKey]?.count,
    )

    if (allGames[categoryKey].length >= totalCount && !fresh) return

    const currentPage = fresh ? 0 : Math.ceil(allGames[categoryKey].length / 45)
    setLoadMoreLoading(true)
    try {
      const res = await api.get<Game[]>(
        `/data/games_v2.php?category=${categoryKey}&provider=${
          platform ?? ''
        }&account=${username}&page=${currentPage + 1}`,
      )
      setCategoryGames(categoryKey, res.data, fresh)
    } catch (error) {
      console.error(error)
    }
    setLoadMoreLoading(false)
  }

  return {
    loadMoreGames,
    loadMoreLoading,
  }
}

export default useGames
