import type { StateCreator } from 'zustand'

import type { AllSlices } from '../types'
import type { CompetitionsSlice } from '../types/competitions'

export const createCompetitionsSlice: StateCreator<
  AllSlices,
  [],
  [],
  CompetitionsSlice
> = set => ({
  competitionUrl: '',
  setCompetitionUrl: url =>
    set(state => ({
      competitions: { ...state.competitions, competitionUrl: url },
    })),
})
