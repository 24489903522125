import type { AxiosRequestConfig } from 'axios'

import { SPANISH_SUBDIRECTORY } from '../../constants/api'
import { isApi2 } from './isApi2'

export function getApiOrigin(axiosRequestConfig: AxiosRequestConfig) {
  let domain = import.meta.env.VITE_API_URL
  if (isApi2(axiosRequestConfig)) domain = import.meta.env.VITE_API_URL_2

  const subdirectory = SPANISH_SUBDIRECTORY

  return `${domain}${subdirectory}`
}
