import { styled } from '@linaria/react'
import { useEffect } from 'react'

import useDimensions from '../../hooks/useDimensions'
import DepositChannels from '../../pages/deposit/deposit-channels'
import PaymentForm from '../../pages/deposit/payment-form'
import { useBoundStore } from '../../store/store'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.6fr;
`

const ChannelsContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 40px);
  overflow-x: hidden;
  border-right: 4px solid var(--tertiary);
  /* width */
  &::-webkit-scrollbar {
    width: 18px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  .theme-dark &::-webkit-scrollbar-thumb {
    background-color: #000;
  }

  .theme-light &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
  @media (min-width: 1200px) {
    padding: 48px 0 48px 48px;
    max-height: calc(100vh - 100px);
  }
`

const FormContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  padding: 0 24px 24px;
  /* width */
  &::-webkit-scrollbar {
    width: 18px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  .theme-dark &::-webkit-scrollbar-thumb {
    background-color: #000;
  }

  .theme-light &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
  @media (min-width: 1200px) {
    padding: 48px 96px;
    max-height: calc(100vh - 100px);
  }
`

const Deposit = () => {
  const { isDesktop } = useDimensions()
  const selectedPaymentChannel = useBoundStore(
    state => state.payment.selectedPaymentChannel,
  )
  const setSelectedPaymentChannel = useBoundStore(
    state => state.payment.setSelectedPaymentChannel,
  )
  const paymentChannels = useBoundStore(state => state.payment.paymentChannels)

  useEffect(() => {
    if (!paymentChannels.length) return
    isDesktop && setSelectedPaymentChannel(paymentChannels[0])
  }, [])

  if (isDesktop) {
    return (
      <Grid>
        <ChannelsContainer>
          <DepositChannels channels={paymentChannels} />
        </ChannelsContainer>
        <FormContainer>
          <PaymentForm />
        </FormContainer>
      </Grid>
    )
  }

  return (
    <div>
      {selectedPaymentChannel ? (
        <FormContainer>
          <PaymentForm />
        </FormContainer>
      ) : (
        <ChannelsContainer>
          <DepositChannels channels={paymentChannels} />
        </ChannelsContainer>
      )}
    </div>
  )
}

export default Deposit
