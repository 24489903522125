import { RouterProvider } from '@tanstack/router'
import smoothscroll from 'smoothscroll-polyfill'

import withLogin from './hoc/withLogin'
import { router } from './router'

smoothscroll.polyfill()

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}

export default withLogin(App)
