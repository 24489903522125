import { lazy,Route } from '@tanstack/router'

import { profileRoute } from '.'

const FreeSpins = lazy(() => import('../../pages/profile/free-spins'))

export const freeSpinsRoute = new Route({
  getParentRoute: () => profileRoute,
  path: 'free-spins',
  component: FreeSpins,
})
