import { styled } from '@linaria/react'
import type { CSSProperties } from 'react'

import { TailSpin } from '../loaders'

const Container = styled.div`
  width: 100%;
`

interface SpinnerProps {
  containerStyle?: CSSProperties
  spinnerSize?: number
}

const Spinner = ({ containerStyle, spinnerSize }: SpinnerProps) => {
  return (
    <Container className="flx-ctr" style={containerStyle}>
      <TailSpin
        color={'#00A6FF'}
        width={spinnerSize ?? 40}
        height={spinnerSize ?? 40}
      />
    </Container>
  )
}

export default Spinner
