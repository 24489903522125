import aes from 'crypto-js/aes'
import utf8 from 'crypto-js/enc-utf8'

import type { LoginCredentials } from '../types/user'

const key = 'super_secret_key'

export const encryptUser = (user: LoginCredentials) => {
  localStorage.setItem(
    'user',
    aes.encrypt(JSON.stringify(user), key).toString(),
  )
}

export const decryptUser = (): null | LoginCredentials => {
  const user = localStorage.getItem('user')
  if (!user) return null
  return JSON.parse(aes.decrypt(user, key).toString(utf8))
}

export const clearUser = () => {
  localStorage.removeItem('user')
  localStorage.removeItem('privateAuth')
}

export const validateEmail = (email: string) => {
  const allowedChars = /^[A-Za-z0-9!#$%&'*+\-/=?^_`{|}"()<>@[\]\\,;:.]+$/

  if (!allowedChars.test(email)) {
    return null
  }

  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
}
