import type { StateCreator } from 'zustand'

import { INITIAL_ALL_GAMES } from '../../constants/casino'
import type { AllSlices } from '../types'
import type { CasinoSlice } from '../types/casino'

export const createCasinoSlice: StateCreator<
  AllSlices,
  [],
  [],
  CasinoSlice
> = set => ({
  allGames: INITIAL_ALL_GAMES,
  setCategoryGames: (categoryKey, categoyGames, fresh) => {
    set(state => ({
      casino: {
        ...state.casino,
        allGames: {
          ...state.casino.allGames,
          [categoryKey]: fresh
            ? categoyGames
            : [...state.casino.allGames[categoryKey], ...categoyGames],
        },
      },
    }))
  },
  clearCategoryGames: categoryKey => {
    set(state => ({
      casino: {
        ...state.casino,
        allGames: {
          ...state.casino.allGames,
          [categoryKey]: [],
        },
      },
    }))
  },
  summary: null,
  setSummary: summary => {
    set(state => ({
      casino: { ...state.casino, summary },
    }))
  },
  loadMoreLoading: false,
  setLoadMoreLoading: loadMoreLoading => {
    set(state => ({
      casino: { ...state.casino, loadMoreLoading },
    }))
  },
})
