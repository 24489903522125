import { styled } from '@linaria/react'
import { useTranslation } from 'react-i18next'

import { numberWithCommas } from '../../utils/number'

const Container = styled.button`
  padding: 0 6px;
  background-color: var(--secondary);
  display: block;
  width: 100%;
  transition: all 0.2s ease-in-out;
  @media (min-width: 1200px) {
    background-color: transparent;
    &.selected {
      background-color: var(--secondary);
    }
    &:hover {
      background-color: var(--secondary);
    }
  }
`

const Content = styled.div`
  padding: 0 16px;
  height: 60px;
  border-bottom: 1px solid var(--secondary-txt-10);
  svg {
    fill: var(--txt);
  }
`

const ChannelIcon = styled.div`
  height: 35px;
  display: flex;
  width: 100px;
`

const Label = styled.span`
  font-size: 14px;
  color: var(--txt);
  font-family: 'Poppins';
  font-weight: 400;
  @media (min-width: 1200px) {
    font-size: 18px;
  }
`

const Limit = styled.p`
  font-family: 'Poppins';
  font-size: 14px;
  color: var(--txt);
  span {
    font-weight: 700;
  }
`

const Divider = styled.div`
  height: 10px;
  width: 1px;
  background-color: var(--txt);
  margin: 0 4px;
`

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

interface PlatformProps {
  link?: string
  label: string
  note?: string
  onClick?: () => void
  active?: boolean
  icon: string
  payLimit?: {
    minimum: number
    maximum: number
  }
}

const Platform = ({
  label,
  active,
  onClick,
  icon,
  payLimit,
}: PlatformProps) => {
  const { t } = useTranslation('walletManagement')
  return (
    <Container className={active ? 'selected' : ''}>
      <Content className={'flx-btw-ctr'} onClick={onClick}>
        <ChannelIcon>
          <img src={icon} alt="Alipay" />
        </ChannelIcon>
        <RightColumn>
          <Label>{label}</Label>
          {payLimit && (
            <div className="flx-ctr">
              <Limit>
                {t('min')} <span>${numberWithCommas(Number(payLimit.minimum))}</span>
              </Limit>
              <Divider />
              <Limit>
                {t('max')} <span>${numberWithCommas(Number(payLimit.maximum))}</span>
              </Limit>
            </div>
          )}
        </RightColumn>
      </Content>
    </Container>
  )
}

export default Platform
