import type { NiceModalHandler} from '@ebay/nice-modal-react'
import { useModal } from '@ebay/nice-modal-react'
import type { Dispatch, SetStateAction} from 'react'
import { useEffect } from 'react'

import { ToastMessage } from '../components/common'
import FillUsername from '../components/register/fill-username'
import type { RegisterSearch } from '../routes/register'
import { useBoundStore } from '../store/store'
import type { ApiResponse } from '../types/api'
import type { ThirdPartyPlatforms } from '../types/common'
import type { UserInfo } from '../types/user'
import { api } from '../utils/api-client'
import closeModal from '../utils/closeModal'
import { getDeviceInfo } from '../utils/device'
import { encryptUser } from '../utils/user'

const useAppleLogin = (
  setThirdPartyLoading: Dispatch<SetStateAction<ThirdPartyPlatforms | null>>,
  callback?: (user: UserInfo, status: number) => void,
) => {
  const setUser = useBoundStore(state => state.user.setUser)
  const fillUsername = useModal(FillUsername)
  const toastMessage = useModal(ToastMessage)

  function parseJwt(token: string) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join(''),
    )

    return JSON.parse(jsonPayload)
  }

  const appleLogin = async (
    token: string,
    user?: {
      username: string
      promo_code: string
    },
    auth_id?: string,
  ) => {
    try {
      const adsInfo = JSON.parse(
        localStorage.getItem('ads_info') ?? '{}',
      ) as RegisterSearch

      const userData = token ? parseJwt(token) : {}

      const response = await api.post<
        ApiResponse<
          (UserInfo & { password?: string; auth_id?: string }) | string
        >
      >('/oauth/apple/callback2.php', {
        ...(userData ?? {}),
        ...(user ?? {}),
        auth_id,
        meta: {
          ...getDeviceInfo(),
          ...(adsInfo ?? {}),
        },
      })
      const { status, info } = response.data

      if (status === 0 && typeof info === 'string') {
        toastMessage.show({ text: info, isValid: false })
        setThirdPartyLoading(null)
        return
      }

      if ((status === 1 || status === 2) && typeof info !== 'string') {
        if (info.auth_id && info.email) {
          fillUsername.show({
            onSubmit: user => appleLogin('', user, info.auth_id),
          })
          return
        } else if (info.username) {
          encryptUser({
            username: info.username,
            password: info.password as string,
          })
          delete info.password
          setUser(info)

          setThirdPartyLoading(null)
          closeModal(fillUsername as NiceModalHandler)
          callback?.(info, user ? 2 : 1)
          return
        }
      }
    } catch (error) {
      console.error(error)
      return
    }
  }

  useEffect(() => {
    const onSuccess = (event: {
      detail: { authorization: { code: string; id_token: string } }
    }) => {
      const { authorization } = event.detail
      appleLogin(authorization.id_token)
    }

    const onError = (event: { detail: { error: string } }) => {
      toastMessage.show({ text: event.detail.error, isValid: false })
      setThirdPartyLoading(null)
    }

    // Listen for authorization success.
    // @ts-expect-error
    document.addEventListener('AppleIDSignInOnSuccess', onSuccess)

    // Listen for authorization failures.
    // @ts-expect-error
    document.addEventListener('AppleIDSignInOnFailure', onError)

    return () => {
      // Listen for authorization success.
      // @ts-expect-error
      document.removeEventListener('AppleIDSignInOnSuccess', onSuccess)

      // Listen for authorization failures.
      // @ts-expect-error
      document.removeEventListener('AppleIDSignInOnFailure', onError)
    }
  }, [])

  return appleLogin
}

export default useAppleLogin
