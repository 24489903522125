import { lazy,Route } from '@tanstack/router'

import { rootRoute } from './root'

const BetbySports = lazy(() => import('../pages/sports/betby-sports'))

type Search = {
  id?: string
}

export const betbySportsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/sports',
  component: BetbySports,
  validateSearch: (search: Record<string, unknown>): Search => {
    return {
      id: typeof search.id === 'string' ? search.id : '',
    }
  },
})
