import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { styled } from '@linaria/react'
import { useEffect,useMemo } from 'react'
import { Portal } from 'react-portal'

import closeModal from '../../utils/closeModal'

const Container = styled.div`
  @keyframes fadeIn {
    0% {
      top: 0px;
      opacity: 0;
    } 100% {
      top: 10px;
      opacity: 1;
    }
  }
  display: grid;
  align-items: center;
  grid-template-columns: 40px 1fr;
  width: 100%;
  max-width: 350px;
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px;
  border-width: 1px;
  border-color: #888;
  border-style: solid;
  background-color: #e7e7ee;
  z-index: 500;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 0.2s;
  transition: all 0.2s;
  &.active {
    top: 10px;
    opacity: 1;
  }
  .icon-container {
    width: 40px;
    height: 100%;
    background-color: #888;
    padding: 10px;
    display: flex;
  }
  p {
    font-size: 14px;
    font-family: 'Poppins';
    color: #444;
    padding: 8px 12px;
  }
  &.success {
    background-color: #ebf8ea;
    border-color: #41BC2C;
    .icon-container {
      background-color: #41BC2C;
    }
  }
  &.failed {
    background-color: #ffeced;
    border-color: #FF4040;
    .icon-container {
      background-color: #FF4040;
    }
  }
  @media (min-width: 1200px) {
    p {
      font-size: 16px;
      max-width: 500px;
    }
    &.active {
      top: 20px;
    }
  }
`

interface ToastMessageProps {
  text: string
  isValid?: boolean
}

const ToastMessage = ({ text, isValid }: ToastMessageProps) => {
  const modal = useModal()

  useEffect(() => {
    if (modal.visible) {
      setTimeout(() => closeModal(modal), 5000)
    }
  }, [modal.visible])

  const className = useMemo(() => {
    if (isValid) return 'success'
    if (isValid === false) return 'failed'
    return ''
  }, [isValid])

  return (
    <Portal>
      <Container className={(modal.visible ? 'active ' : '') + className}>
        <div className="icon-container">
          {isValid && (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.0001 0C4.486 0 0 4.48594 0 9.99994C0 15.5139 4.486 19.9999 10.0001 19.9999C15.5141 19.9999 20 15.5139 20 9.99994C20 4.48594 15.5141 0 10.0001 0ZM10.0001 18.3605C5.38993 18.3605 1.63933 14.6101 1.63933 9.99994C1.63933 5.38987 5.38993 1.63933 10.0001 1.63933C14.6101 1.63933 18.3607 5.38987 18.3607 9.99994C18.3607 14.6101 14.6101 18.3605 10.0001 18.3605Z"
                fill="white"
              />
              <path
                d="M14.3257 6.16191L8.38827 12.0994L5.67367 9.38478C5.35354 9.06472 4.83459 9.06472 4.51446 9.38478C4.1944 9.70491 4.1944 10.2239 4.51446 10.544L7.80867 13.8382C7.96873 13.9982 8.1785 14.0783 8.38827 14.0783C8.59804 14.0783 8.80781 13.9982 8.96788 13.8382L15.4849 7.32119C15.8049 7.00106 15.8049 6.48211 15.4849 6.16198C15.1648 5.84185 14.6457 5.84185 14.3257 6.16191Z"
                fill="white"
              />
            </svg>
          )}
          {isValid === false && (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1523_4433)">
                <path
                  d="M10.0001 0C4.486 0 0 4.48594 0 9.99994C0 15.5139 4.486 19.9999 10.0001 19.9999C15.5141 19.9999 20 15.5139 20 9.99994C20 4.48594 15.5141 0 10.0001 0ZM10.0001 18.3605C5.38993 18.3605 1.63933 14.6101 1.63933 9.99994C1.63933 5.38987 5.38993 1.63933 10.0001 1.63933C14.6101 1.63933 18.3607 5.38987 18.3607 9.99994C18.3607 14.6101 14.6101 18.3605 10.0001 18.3605Z"
                  fill="white"
                />
                <path
                  d="M6.25 10H13.75"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1523_4433">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
          {isValid === undefined && (
            <svg
              width={20}
              height={20}
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              fill="none"
              stroke="#fff">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
              />
            </svg>
          )}
        </div>
        <p>{text}</p>
      </Container>
    </Portal>
  )
}

export default NiceModal.create(ToastMessage)
