import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import { useTranslation } from 'react-i18next'

import closeModal from '../../utils/closeModal'
import Modal from '../common/modal'
import Spinner from '../common/spinner'

const container = css`
  @media (min-width: 1200px) {
    max-width: 450px;
  }
`

const Button = styled.button`
  width: 120px;
  text-align: center;
  font-size: 18px;
  padding: 7px 0;
  background-color: var(--secondary-txt-10);
  color: var(--highlight);
  border-radius: 22px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 500;
  @media (min-width: 1200px) {
    font-size: 24px;
    width: 170px;
    border-radius: 26px;
  }
`

const Title = styled.p`
  color: var(--txt);
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
  text-align: center;
  @media (min-width: 1200px) {
    font-size: 30px;
  }
`

const Text = styled.p`
  font-size: 15px;
  color: var(--txt);
  text-align: center;
  font-weight: 500;
  margin-bottom: 24px;
  padding: 0 50px;
  span {
    font-weight: 700;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
  }
`

interface CancelTransactionModalProps {
  isLoading: boolean
  onClick: () => Promise<void>
}

const CancelTransactionModal = ({
  isLoading,
  onClick,
}: CancelTransactionModalProps) => {
  const modal = useModal()
  const { t } = useTranslation(['common', 'walletManagement'])

  return (
    <Modal id="cancel-transaction-modal" containerStyle={container} zIndex={220}>
      <Title>{t('cancel-transaction', { ns: 'walletManagement' })}</Title>
      <Text>{t('cancel-sure', { ns: 'walletManagement' })}</Text>
      <div className="flx-ctr" style={{ gap: '16px' }}>
        {isLoading ? (
          <Spinner containerStyle={{ width: '120px' }} spinnerSize={30} />
        ) : (
          <Button onClick={onClick}>{t('yes', { ns: 'common' })}</Button>
        )}
        <Button onClick={() => closeModal(modal)}>
          {t('no', { ns: 'common' })}
        </Button>
      </div>
    </Modal>
  )
}

export default NiceModal.create(CancelTransactionModal)
