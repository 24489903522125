import { styled } from '@linaria/react'

import customerService from '../../assets/common/customer_service.svg'

const Container = styled.button`
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  * {
    pointer-events: none;
  }
  &:hover {
    transform: scale(1.05);
  }
`

export const CustomerServiceButton = () => {
  return (
    <Container
      id="float-customer-service"
      aria-label="Contact customer service">
      <img
        src={customerService}
        style={{ width: 50, height: 51.66 }}
        alt="customer service button"
      />
    </Container>
  )
}
