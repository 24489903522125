import { lazy,Route } from '@tanstack/router'

import { casinoRoute } from '.'

const Game = lazy(() => import('../../pages/game'))  

export const gameRoute = new Route({
  getParentRoute: () => casinoRoute,
  path: '$category/$game',
  component: Game,
})
