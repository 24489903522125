import { lazy,Route } from '@tanstack/router'

import { rootRoute } from './root'

const Home = lazy(() => import('../pages/home'))

type IndexSearch = {
  bonus?: string
  reset_password?: string
  redirectUrl?: string
  referral?: string
  code?: string
}

export const indexRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/',
  component: Home,
  validateSearch: (search: Record<string, unknown>): IndexSearch => {
    return search
  },
})
