import { useEffect } from 'react'

import { decryptUser } from '../utils/user'

function useAdsInfo() {
  const params = new URLSearchParams(window.location.search)

  useEffect(() => {
    const user = decryptUser()
    const search = Object.fromEntries(params.entries())
    const adsParams = ['utm_source', 'utm_campaign', 'utm_medium', 'auid', 'referral', 'utm_content']

    if (adsParams.some(param => !!search[param]) && !user) {
      localStorage.setItem('ads_info', JSON.stringify(search))
    }
  }, [params])
}

export default useAdsInfo
