import { lazy,Route } from '@tanstack/router'

import { rootRoute } from './root'

const AffiliateSms = lazy(() => import('../pages/affiliate-sms'))

export const affiliateSmsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/sms/$affiliate',
  component: AffiliateSms,
})
