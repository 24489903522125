import NiceModal from '@ebay/nice-modal-react'
import { styled } from '@linaria/react'
import { useTranslation } from 'react-i18next'

import duplicate_icon from '../../assets/common/duplicate_icon.webp'
import Modal from './modal'

const Icon = styled.img`
  width: 140px;
  margin-bottom: 16px;
`

const Title = styled.p`
  color: var(--txt);
  font-size: 16px;
  font-family: 'Syncopate-Bold';
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
  @media (min-width: 1200px) {
    font-size: 18px;
  }
`

const Text = styled.p`
  font-size: 15px;
  color: var(--txt);
  text-align: center;
  font-weight: 400;
  margin-bottom: 12px;
  span {
    font-weight: 700;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
  }
`

const Button = styled.button`
  height: 35px;
  background-color: #46aef7;
  border-radius: 2px;
  width: 170px;
  &.secondary {
    margin-top: 12px;
    background-color: #888;
  }
  p {
    font-size: 10px;
    color: #fff;
    font-family: 'Syncopate-Bold';
    text-transform: uppercase;
  }
  @media (min-width: 1200px) {
    width: 190px;
    p {
      font-size: 12px;
    }
  }
`

const DuplicateAccountModal = () => {
  const { t } = useTranslation('duplicateAccount')
  return (
    <Modal id='duplicate-account-modal'>
      <div className='v-flx-ctr'>
        <Icon src={duplicate_icon} alt="Duplicate" />
        <Title>{t('title')}</Title>
        <Text>{t('already-registered')}</Text>
        <Button>
          <p>{t('contact')}</p>
        </Button>
        <Button className='secondary'>
          <p>{t('got-it')}</p>
        </Button>
      </div>
    </Modal>
  )
}

export default NiceModal.create(DuplicateAccountModal)