import type { StateCreator } from 'zustand'

import type { AllSlices } from '../types'
import type { PaymentSlice } from '../types/payment'

export const createPaymentSlice: StateCreator<
  AllSlices,
  [],
  [],
  PaymentSlice
> = set => ({
  selectedPaymentChannel: null,
  setSelectedPaymentChannel: paymentChannel =>
    set(state => ({
      payment: { ...state.payment, selectedPaymentChannel: paymentChannel },
    })),
  paymentChannels: [],
  setPaymentChannels: paymentChannels =>
    set(state => ({ payment: { ...state.payment, paymentChannels } })),
})
