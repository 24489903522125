import { useModal } from '@ebay/nice-modal-react'
import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import type { Dispatch, SetStateAction} from 'react'
import {useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import wallet_card_icon from '../../assets/wallet-management/wallet_card_icon.webp'
import {
  DefaultButton,
  MessageModal,
  Spinner,
  WebTitle,
} from '../../components/common'
import Icon from '../../components/icon'
import type { ApiResponse } from '../../types/api'
import type { WalletDetails } from '../../types/wallet-management'
import { api } from '../../utils/api-client'

const Wrapper = styled.div`
  position: relative;
  @media (min-width: 1200px) {
    border-radius: 8px;
    max-height: 500px;
    padding: 0;
    overflow-y: auto;
    min-height: unset;
    width: 400px;
    margin: 0 auto;
  }
`

const addButtonCss = css`
  display: flex !important;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 12px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 48px;
  margin-bottom: 40px;
`

const EmptyImg = styled.img`
  width: 140px;
  @media (min-width: 1200px) {
    width: 170px;
  }
`

const AccountsHeader = styled.div`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  color: var(--txt);
  margin-top: 14px;
  margin-bottom: 5px;
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

const CardList = styled.div`
  margin-bottom: 24px;
`

const Card = styled.div`
  height: 150px;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0px;
  }
  &:nth-child(3n-1) {
    background: linear-gradient(280.52deg, #3a57f1 0.04%, #43cdfd 99.94%);
  }
  &:nth-child(3n-2) {
    background: linear-gradient(280.52deg, #ff845e 0.04%, #ff956b 99.94%);
  }
  &:nth-child(3n-3) {
    background: linear-gradient(280.52deg, #ff6b6b 0.06%, #ff8a8a 99.97%);
  }
`

const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const CardColumn = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex: 1 1 0;
`

const CardLabel = styled.span`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

const CardText = styled.span`
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  @media (min-width: 1200px) {
    font-size: 14px;
  }
`

const cardButtonCss = css`
  height: 25px;
  flex: 1 1 0;
  border: 1px solid #ffffff;
  border-radius: 25px;
  font-size: 12px;
  line-height: 25px !important;
  background-color: transparent !important;
  @media (min-width: 1200px) {
    font-size: 14px;
    line-height: 30px;
    height: 30px;
    border-radius: 30px;
  }
`

interface Props {
  setActiveTab: Dispatch<SetStateAction<'withdraw' | 'add-bank' | 'bank-list'>>
}

const BanksList = ({ setActiveTab }: Props) => {
  const [bankList, setBankList] = useState<WalletDetails[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const messageModal = useModal(MessageModal)
  const { t } = useTranslation('walletManagement')

  const getBankList = async () => {
    const getBankListInner = async () => {
      const response = await api.post<ApiResponse<WalletDetails[]>>(
        '/ajax_data.php',
        { type: 'bank_list' },
      )
      const { status, info } = response.data
      if (status === 1) {
        setBankList(info)
      }
    }
    setIsLoading(true)
    await getBankListInner()
    setIsLoading(false)
  }

  useEffect(() => {
    getBankList()
  }, [])

  const onDelete = async (id: string) => {
    setIsLoading(true)
    const reqBody = {
      type: 'bank_unbind',
      id,
    }
    const response = await api.post<ApiResponse<string>>(
      '/ajax_data.php',
      reqBody,
    )
    const { info, status } = response.data
    messageModal.show({
      title: '',
      message: info,
    })
    setIsLoading(false)
    if (status === 1) {
      setBankList(prev => prev.filter(prevBank => +prevBank.id !== +id))
    }
  }

  const renderBankList = () => {
    if (isLoading)
      return (
        <Container>
          <Spinner />
        </Container>
      )
    if (bankList.length === 0)
      return (
        <Container>
          <EmptyImg src={wallet_card_icon} />
        </Container>
      )
    return (
      <CardList>
        <AccountsHeader>{t('no-of-bank')}({bankList.length})</AccountsHeader>
        {bankList.map(bank => (
          <Card key={bank.id}>
            <CardRow>
              <CardColumn>
                <CardLabel>
                  {+bank.is_crypto
                    ? `${bank.currency}/${bank.network}`
                    : t('bank-code')}
                </CardLabel>
                <CardText>{bank.bank_type}</CardText>
              </CardColumn>
              <CardColumn>
                <CardLabel>{+bank.is_crypto ? 'WALLET' : 'CLABE'}</CardLabel>
                <CardText>
                  {+bank.is_crypto
                    ? `${bank.bank_no.slice(0, 4)}***${bank.bank_no.slice(-4)}`
                    : bank.bank_no}
                </CardText>
              </CardColumn>
            </CardRow>
            <CardRow>
              <CardColumn>
                <CardLabel>{t('name')}</CardLabel>
                <CardText>{bank.realname}</CardText>
              </CardColumn>
              <DefaultButton
                variant="blue"
                width={120}
                className={cardButtonCss}
                type="button"
                onClick={() => {
                  onDelete(bank.id)
                }}>
                {+bank.is_crypto ? `${t('delete')} Crypto` : t('delete-bank')}
              </DefaultButton>
            </CardRow>
          </Card>
        ))}
      </CardList>
    )
  }

  return (
    <Wrapper>
      <WebTitle />
      {renderBankList()}
      <div className="flx-ctr">
        <DefaultButton
          variant="blue"
          width={'100%'}
          className={addButtonCss}
          type="button"
          onClick={() => setActiveTab('add-bank')}>
          <Icon id="plus" width="15" height="15" fill="#fff" />
          {t('add-account', { ns: 'walletManagement' })}
        </DefaultButton>
      </div>
    </Wrapper>
  )
}

export default BanksList
