import type { CSSProperties } from 'react'

interface TailSpinProps {
  width?: number
  height?: number
  color?: CSSProperties['color']
}

const TailSpin = ({ width, height, color }: TailSpinProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? 38}
      height={height ?? 38}
      viewBox="0 0 40 40">
      <defs>
        <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
          <stop stopColor={color ?? '#fff'} stopOpacity="0" offset="0%" />
          <stop
            stopColor={color ?? '#fff'}
            stopOpacity=".631"
            offset="63.146%"
          />
          <stop stopColor={color ?? '#fff'} offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(2 2)">
          <path
            d="M36 18c0-9.94-8.06-18-18-18"
            id="Oval-2"
            stroke="url(#a)"
            strokeWidth="4">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </path>
          <circle fill={color ?? '#fff'} cx="36" cy="18" r="2">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </g>
    </svg>
  )
}

export default TailSpin
