import { lazy,Route } from '@tanstack/router'

import type { RegisterSearch } from './register'
import { rootRoute } from './root'

const CasinoAds = lazy(() => import('../pages/casino-ads'))

export const casinoAdsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/casino-ads',
  component: CasinoAds,
  validateSearch: (search: Record<string, unknown>): RegisterSearch => {
    return search
  },
})
