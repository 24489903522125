import type { StateCreator } from 'zustand'

import type { AllSlices } from '../types'
import type { SettingsSlice, SettingsSliceStates } from '../types/settings'

export const createSettingsSlice: StateCreator<
  AllSlices,
  [],
  [],
  SettingsSlice
> = set => ({
  register_birthday: {
    type: 'status',
    value: 1,
  },
  vip_birthdayBonus: {
    type: 'html',
    value: '',
  },
  vip_freePlay: {
    type: 'html',
    value: '',
  },
  vip_monthlyBonus: {
    type: 'html',
    value: '',
  },
  vip_multipleSharing: {
    type: 'html',
    value: '',
  },
  vip_rescueBonus: {
    type: 'html',
    value: '',
  },
  vip_vipBonus: {
    type: 'html',
    value: '',
  },
  vip_fb777: {
    type: 'html',
    value: '',
  },
  vip_promoCode: {
    type: 'html',
    value: '',
  },
  login_facebook: {
    type: 'status',
    value: 0,
  },
  login_google: {
    type: 'status',
    value: 0,
  },
  login_telegram: {
    type: 'status',
    value: 0,
  },
  login_twitch: {
    type: 'status',
    value: 0,
  },
  login_twitter: {
    type: 'status',
    value: 0,
  },
  login_ios: {
    type: 'status',
    value: 0,
  },
  social_media_auth: {
    type: 'status',
    value: 0,
  },
  redeem_points: {
    type: 'status',
    value: 0,
  },
  bonus_daily: {
    type: 'status',
    value: 0,
  },
  bonus_coinplay: {
    type: 'status',
    value: 0,
  },
  bonus_coin_play: {
    type: 'html',
    value: '',
  },
  bonus_Xmas: {
    type: 'status',
    value: 0,
  },
  register_banner1: {
    type: 'link',
    value: null,
    click_url: '',
  },
  register_banner2: {
    type: 'link',
    value: null,
    click_url: '',
  },
  register_banner3: {
    type: 'link',
    value: null,
    click_url: '',
  },
  register_banner4: {
    type: 'link',
    value: null,
    click_url: '',
  },
  register_banner5: {
    type: 'link',
    value: null,
    click_url: '',
  },
  register_banner6: {
    type: 'link',
    value: null,
    click_url: '',
  },
  login_banner1: {
    type: 'link',
    value: null,
    click_url: '',
  },
  login_banner2: {
    type: 'link',
    value: null,
    click_url: '',
  },
  login_banner3: {
    type: 'link',
    value: null,
    click_url: '',
  },
  login_banner4: {
    type: 'link',
    value: null,
    click_url: '',
  },
  login_banner5: {
    type: 'link',
    value: null,
    click_url: '',
  },
  login_banner6: {
    type: 'link',
    value: null,
    click_url: '',
  },
  setSettings: (settings: Partial<SettingsSliceStates>) =>
    set(state => {
      const {
        login_facebook,
        login_google,
        login_telegram,
        login_twitch,
        login_twitter,
        login_ios,
      } = settings
      return {
        settings: {
          ...state.settings,
          ...settings,
          social_media_auth: {
            type: 'status',
            value:
              login_facebook?.value ||
              login_google?.value ||
              login_telegram?.value ||
              login_twitch?.value ||
              login_twitter?.value ||
              login_ios?.value
                ? 1
                : 0,
          },
        },
      }
    }),
})
