import { lazy,Route } from '@tanstack/router'

import { rootRoute } from './root'

const Competitions = lazy(() => import('../pages/competitions'))

export const competitionsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'sorteos',
  component: Competitions,
})
