import sha256 from 'crypto-js/sha256'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { useBoundStore } from '../../store/store'
import { api } from '../../utils/api-client'

const METADATA = {
  "home": {
    title: 'El Casino Mas Serio de Mexico, tu retiro en menos de 20 minutos',
    desc: 'El Casino no.1 en Asia, ahora en México, Recibe 50 pesos de regalo sin rollover, bonificamos el 10% de todos tus depósitos.',
    keywords: '',
  },
  "casino": {
    title: 'Casino',
    desc: 'Los mejores juegos online. Casino en vivo las 24 horas. Jackpots, Slots, Ruleta, Blackjack, Póker y más. 2000 juegos de casino esperando que te lleves el premio. Gana dinero real.',
    keywords: '',
  },
  "deposit": {
    title: 'Deportes',
    desc: 'Casa de apuestas deportivas en línea. Apuesta a tu equipo favorito. Depósitos instantáneos. Transacciones seguras. Cobra tus ganancias fácilmente.',
    keywords: '',
  },
  "promotions": {
    title: 'Promociones',
    desc: 'Duplicamos tu 1er depósito hasta $5,000 MXN y hasta $11,000 MXN de bono de bienvenida. Refiere a tus amigos y gana comisión. Con MexPlay ganas más.',
    keywords: '',
  },
}

interface WebTitleProps {
  page?: keyof typeof METADATA
}

const WebTitle = ({ page }: WebTitleProps) => {
  const email = useBoundStore(state => state.user.email)
  const phone = useBoundStore(state => state.user.phone)
  const userId = useBoundStore(state => state.user.userID)
  const birthday = useBoundStore(state => state.user.birthday)
  const firstName = useBoundStore(state => state.user.first_name)
  const lastName = useBoundStore(state => state.user.last_name)
  const isUserLoading = useBoundStore(state => state.user.isLoading)

  useEffect(() => {
    if (isUserLoading) return
    api.fbGraph({
      data: [
        {
          action_source: 'website',
          event_id: 12340,
          event_name: 'ViewContent',
          event_time: dayjs().unix(),
          user_data: {
            em: [email ? sha256(email).toString() : null],
            ph: [phone ? sha256(phone).toString() : null],
            client_user_agent: navigator.userAgent,
            external_id: [userId ? sha256(userId).toString() : null],
            db: [birthday ? sha256(birthday).toString() : null],
            fn: [firstName ? sha256(firstName).toString() : null],
            ln: [lastName ? sha256(lastName).toString() : null],
          },
          custom_data: {
            page,
          },
        },
      ],
    })
    api.fbGraph2({
      data: [
        {
          action_source: 'website',
          event_id: 12340,
          event_name: 'ViewContent',
          event_time: dayjs().unix(),
          user_data: {
            em: [email ? sha256(email).toString() : null],
            ph: [phone ? sha256(phone).toString() : null],
            client_user_agent: navigator.userAgent,
            external_id: [userId ? sha256(userId).toString() : null],
            db: [birthday ? sha256(birthday).toString() : null],
            fn: [firstName ? sha256(firstName).toString() : null],
            ln: [lastName ? sha256(lastName).toString() : null],
          },
          custom_data: {
            page,
          },
        },
      ],
    })
  }, [isUserLoading])

  return (
    <Helmet>
      <title>{METADATA[page ?? 'home'].title}</title>
      {page && <meta name="description" content={METADATA[page].desc} />}
      {page && METADATA[page].keywords && (
        <meta name="keywords" content={METADATA[page].keywords} />
      )}
      <link rel="canonical" href={window.location.href.replace(/\/+$/, "")} />
    </Helmet>
  )
}

export default WebTitle
