import { lazy,Route } from '@tanstack/router'

import { rootRoute } from './root'

const BtiSports = lazy(() => import('../pages/sports/bti-sports'))

export const btiSportsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/sports/bti',
  component: BtiSports,
})
