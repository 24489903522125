import { lazy,Route } from '@tanstack/router'

import { rootRoute } from './root'

const ContactUs = lazy(() => import('../pages/contact-us'))

export const contactUsRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'contact-us',
  component: ContactUs,
})
