import { lazy,Route } from '@tanstack/router'

import { rootRoute } from './root'

const AffiliateHome = lazy(() => import('../pages/affiliate-home'))

export const affiliateHomeRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/affiliate/$affiliate',
  component: AffiliateHome,
})
