import { styled } from '@linaria/react'
import type { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { TextButton } from '../common'

const Container = styled.div`
  margin-bottom: 20px;
  align-items: flex-start;
  margin-top: 16px;
`

const Text = styled.p`
  font-size: 14px;
  color: var(--secondary-txt);
  margin-left: 8px;
  font-family: 'Poppins';
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

const Checkbox = styled.button`
  width: 16px;
  height: 16px;
  min-width: 16px;
  border-radius: 2px;
  border: 1px solid var(--secondary-txt-50);
  margin-top: 1px;
  svg {
    opacity: 0;
  }
  &.active {
    border: 1px solid var(--highlight);
    background-color: var(--highlight);
    svg {
      opacity: 1;
    }
  }
`

interface TermsCheckboxProps {
  isChecked: boolean
  setIsChecked: Dispatch<SetStateAction<boolean>>
  onClick: () => void
}

const TermsCheckbox = ({
  isChecked,
  setIsChecked,
  onClick,
}: TermsCheckboxProps) => {
  const { t } = useTranslation('walletManagement')

  return (
    <Container className="flx-ctr">
      <Checkbox
        className={isChecked ? 'active flx-ctr' : 'flx-ctr'}
        onClick={() => setIsChecked(prev => (prev ? false : true))}>
        <svg
          width="10"
          viewBox="0 0 8 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.71339 1.12709C7.79769 1.20958 7.83984 1.30975 7.83984 1.4276C7.83984 1.54544 7.79769 1.64561 7.71339 1.7281L4.44371 4.9276L3.82952 5.52861C3.74522 5.6111 3.64285 5.65234 3.52242 5.65234C3.40199 5.65234 3.29963 5.6111 3.21533 5.52861L2.60113 4.9276L0.966295 3.32785C0.881994 3.24536 0.839844 3.14519 0.839844 3.02734C0.839844 2.9095 0.881994 2.80933 0.966295 2.72684L1.58049 2.12583C1.66479 2.04334 1.76716 2.00209 1.88759 2.00209C2.00802 2.00209 2.11038 2.04334 2.19468 2.12583L3.52242 3.42949L6.485 0.526081C6.56931 0.44359 6.67167 0.402344 6.7921 0.402344C6.91253 0.402344 7.0149 0.44359 7.0992 0.526081L7.71339 1.12709Z"
            fill="white"
          />
        </svg>
      </Checkbox>
      <Text>
        {t('read-the-terms')}{' '}
        <TextButton onClick={onClick} variant="blue" inline>
          {t('terms')}
        </TextButton>{' '}
        {t('this-promo')}
      </Text>
    </Container>
  )
}

export default TermsCheckbox
