import { WebTitle } from '../../components/common'
import Platform from '../../components/wallet-management/platform'
import { useBoundStore } from '../../store/store'
import type { PaymentChannel } from '../../types/wallet-management'

interface DepositChannelsProps {
  channels: PaymentChannel[]
}

const DepositChannels = ({ channels }: DepositChannelsProps) => {
  const setSelectedPaymentChannel = useBoundStore(
    state => state.payment.setSelectedPaymentChannel,
  )
  const selectedPaymentChannel = useBoundStore(
    state => state.payment.selectedPaymentChannel,
  )

  return (
    <div>
      <WebTitle page="deposit" />
      {channels.map((item) => (
        <Platform
          key={item.id}
          icon={item.img}
          label={item.show_name}
          onClick={() => {
            setSelectedPaymentChannel(item)
          }}
          active={selectedPaymentChannel?.id === item.id}
          payLimit={item.pay_limit}
        />
      ))}
    </div>
  )
}

export default DepositChannels
