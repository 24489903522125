import { styled } from '@linaria/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddBank from '../../pages/withdraw/add-bank'
import BanksList from '../../pages/withdraw/banks'
import WithdrawForm from '../../pages/withdraw/withdraw-form'

const FormContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 96px);
  overflow-y: auto;
  padding: 0 24px 24px;
  /* width */
  &::-webkit-scrollbar {
    width: 18px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  .theme-dark &::-webkit-scrollbar-thumb {
    background-color: #000;
  }

  .theme-light &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
  @media (min-width: 1200px) {
    padding: 48px;
    max-height: calc(100vh - 100px);
  }
`

const Tabs = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  button {
    font-family: 'Poppins';
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--txt);
    transition: all 0.2s ease-in-out;
    border-bottom: 2px solid transparent;
    &.active,
    &:hover {
      border-bottom: 2px solid var(--highlight);
      color: var(--highlight);
    }
  }
  @media (min-width: 1200px) {
    margin-bottom: 24px;
    gap: 16px;
    button {
      font-size: 18px;
    }
  }
`

// type WithdrawProps = {
//   closeWalletManagementModal: () => void
//   showWalletManagementModal: (page?: number, tab?: number) => void
//   walletManagementDefaultTab?: {
//     tab?: number
//     page?: number
//   }
// }

const Withdraw = () => {
  const { t } = useTranslation('walletManagement')
  const [activeTab, setActiveTab] = useState<
    'withdraw' | 'add-bank' | 'bank-list'
  >('withdraw')

  return (
    <FormContainer>
      <Tabs>
        <button
          className={activeTab === 'withdraw' ? 'active' : ''}
          onClick={() => setActiveTab('withdraw')}>
          {t('withdraw').toUpperCase()}
        </button>
        <button
          className={activeTab === 'bank-list' ? 'active' : ''}
          onClick={() => setActiveTab('bank-list')}>
          {t('bank-list')}
        </button>
        <button
          className={activeTab === 'add-bank' ? 'active' : ''}
          onClick={() => setActiveTab('add-bank')}>
          {t('add-bank')}
        </button>
      </Tabs>
      {activeTab === 'withdraw' && <WithdrawForm />}
      {activeTab === 'bank-list' && <BanksList setActiveTab={setActiveTab} />}
      {activeTab === 'add-bank' && <AddBank setActiveTab={setActiveTab} />}
    </FormContainer>
  )
}

export default Withdraw
