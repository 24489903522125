import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import closeModal from '../../utils/closeModal'
import Modal from '../common/modal'
import Icon from '../icon'

const container = css`
  width: calc(100% - 30px);
  @media (min-width: 1200px) {
    max-width: 600px;
  }
`

const Button = styled.button`
  width: 120px;
  text-align: center;
  font-size: 18px;
  padding: 7px 0;
  background-color: var(--primary-btn-bg);
  border: 1.5px solid var(--primary-btn-bg);
  color: var(--primary-btn-txt);
  border-radius: 22px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 400;
  display: block;
  @media (min-width: 1200px) {
    font-size: 20px;
    padding: 10px 0;
    width: 170px;
    border-radius: 26px;
  }
`

const ButtonContainer = styled.div`
  margin-top: 16px;
  @media (min-width: 1200px) {
    margin-top: 24px;
  }
`

const Checkbox = styled.div`
  div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1.5px solid #999;
    margin-top: -2px;
    svg {
      stroke: #999;
    }
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #999;
    margin-left: 8px;
  }
  @media (min-width: 1200px) {
    p {
      font-size: 16px;
    }
  }
`

const TermsContent = styled.div`
  background-color: var(--primary);
  color: var(--txt);
  margin-bottom: 12px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  * {
    font-weight: 400 !important;
    font-size: 14px !important;
  }

  @media (min-width: 1200px) {
    * {
      font-size: 16px !important;
    }

    margin-bottom: 24px;
    /* width */
    &::-webkit-scrollbar {
      width: 18px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      border-radius: 9px;
      background-clip: content-box;
    }

    .theme-dark &::-webkit-scrollbar-thumb {
      background-color: #000;
    }

    .theme-light &::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
  }
`

export interface HiddenPromos {
  expiryDate: string
  promoIds: string[]
}

interface PromotionsTermsModalProps {
  htmlContent: string
  promoId: string
}

const PromotionsTermsModal = ({
  htmlContent,
  promoId,
}: PromotionsTermsModalProps) => {
  const modal = useModal()
  const { t } = useTranslation('walletManagement')
  const [checked, setChecked] = useState(false)

  const toggle = () => {
    setChecked(prev => {
      const hiddenPromosString = localStorage.getItem('hiddenPromos')
      if (hiddenPromosString) {
        const hiddenPromos: HiddenPromos = JSON.parse(hiddenPromosString)
        const isExpired =
          new Date(hiddenPromos.expiryDate).getDay() !== new Date().getDay()
        if (isExpired) {
          localStorage.setItem(
            'hiddenPromos',
            JSON.stringify({
              expiryDate: new Date(),
              promoIds: [promoId],
            }),
          )
        } else {
          localStorage.setItem(
            'hiddenPromos',
            JSON.stringify({
              expiryDate: hiddenPromos.expiryDate,
              promoIds: prev
                ? hiddenPromos.promoIds.filter(item => item !== promoId)
                : [...hiddenPromos.promoIds, promoId],
            }),
          )
        }
      } else {
        localStorage.setItem(
          'hiddenPromos',
          JSON.stringify({
            expiryDate: new Date(),
            promoIds: [promoId],
          }),
        )
      }

      return prev ? false : true
    })
  }

  useEffect(() => {
    if (!modal.visible) return
    // Check if already checked
    const hiddenPromosString = localStorage.getItem('hiddenPromos')
    if (hiddenPromosString) {
      const hiddenPromos: HiddenPromos = JSON.parse(hiddenPromosString)
      const isExpired = dayjs().diff(dayjs(hiddenPromos.expiryDate), 'days') > 1
      if (isExpired) return
      setChecked(hiddenPromos.promoIds.includes(promoId))
    }
  }, [modal.visible])

  return (
    <Modal id="promotion-terms-modal" containerStyle={container} zIndex={206}>
      <TermsContent dangerouslySetInnerHTML={{ __html: htmlContent }} />
      <Checkbox className="flx-str-ctr" onClick={toggle}>
        <div className='flx-ctr'>
          {checked && <Icon id='check' width={15} height={15} />}
        </div>
        <p>{t('dont-show')}</p>
      </Checkbox>
      <ButtonContainer className="flx-ctr">
        <Button onClick={() => closeModal(modal)}>Ok</Button>
      </ButtonContainer>
    </Modal>
  )
}

export default NiceModal.create(PromotionsTermsModal)
