import { lazy,Route } from '@tanstack/router'

import { rootRoute } from './root'

const Affiliate1076 = lazy(() => import('../pages/affiliate-1076'))

export const affiliate1076Route = new Route({
  getParentRoute: () => rootRoute,
  path: '/1076',
  component: Affiliate1076,
})
