import { styled } from '@linaria/react'
import { useTranslation } from 'react-i18next'

import { numberWithCommas } from '../../utils/number'
import type { AmountInputProps } from '../wallet-management/amount-input'

const Container = styled.div`
  margin-bottom: 12px;
`

const Button = styled.button`
  font-size: 14px;
  font-family: 'Poppins';
  color: var(--txt);
  width: 100%;
  line-height: 45px;
  background-color: var(--secondary);
  border-right: 1px solid var(--secondary-txt-20);
  border-bottom: 1px solid var(--secondary-txt-20);
  transition: all 0.2s ease-in-out;
  &:nth-child(3n) {
    border-right: none;
  }
  &:nth-child(n + 4) {
    border-bottom: none;
  }
  &.active {
    background-color: var(--highlight);
    color: #fff;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`

const GroupLabel = styled.p`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--txt);
  border-bottom: 2px solid var(--highlight);
  display: inline-block;
  margin-bottom: 12px;
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

interface AmountSelectorProps
  extends Pick<AmountInputProps, 'amount' | 'setAmount'> {
  amounts: number[]
}

const AmountSelector = ({
  amount,
  setAmount,
  amounts,
}: AmountSelectorProps) => {
  const { t } = useTranslation('walletManagement')
  return (
    <Container>
      <GroupLabel>{t('amount')}</GroupLabel>
      <Grid>
        {amounts.map((item, index) => (
          <Button
            onClick={() => setAmount(item)}
            className={amount === item ? 'active' : ''}
            key={index}>
            $ {numberWithCommas(item)}
          </Button>
        ))}
      </Grid>
    </Container>
  )
}

export default AmountSelector
