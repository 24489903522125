import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { css } from '@linaria/core'
import { useEffect, useState } from 'react'

import Modal from '../common/modal'
import Deposit from './deposit'
import Tabs from './tabs'
import Withdraw from './withdraw'

const container = css`
  width: 100%;
  height: 100vh;
  max-width: 1100px;
  padding: 0;
  border-radius: 0px;
  border-width: 0px;
  overflow: hidden;
  background-color: var(--primary);
  top: 0;
  left: 0;
  transform: unset;
  &.active {
    transform: unset;
  }
  @media (min-width: 1200px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: unset;
    border-radius: 12px;
    &.active {
      transform: unset;
      transform: translate(-50%, -50%);
    }
  }
`

type WalletManagementModalProps = {
  walletManagementDefaultTab?: {
    tab?: number
  }
}

const WalletManagementModal = ({
  walletManagementDefaultTab,
}: WalletManagementModalProps) => {
  const modal = useModal()
  const [activeTab, setActiveTab] = useState(
    walletManagementDefaultTab?.tab ?? 0,
  )

  useEffect(() => {
    setActiveTab(walletManagementDefaultTab?.tab ?? 0) 
  }, [walletManagementDefaultTab])
  
  return (
    <Modal id="wallet-management-modal" containerStyle={container}>
      <Tabs
        activeTab={activeTab}
        setActiveTab={tab => {
          setActiveTab(tab)
        }}
        hide={modal.hide}
      />
      {activeTab === 0 ? <Deposit /> : <Withdraw />}
    </Modal>
  )
}

export default NiceModal.create(WalletManagementModal)
