import { useEffect } from 'react'

import { useBoundStore } from '../store/store'
import type { ApiResponse } from '../types/api'
import type { PaymentChannel } from '../types/wallet-management'
import { api } from '../utils/api-client'
import { decryptUser } from '../utils/user'

const usePaymentChannels = () => {
  const paymentChannels = useBoundStore(state => state.payment.paymentChannels)
  const setPaymentChannels = useBoundStore(
    state => state.payment.setPaymentChannels,
  )
  const username = useBoundStore(state => state.user.username)

  useEffect(() => {
    const user = decryptUser()
    if (!paymentChannels.length && user?.username) {
      api
        .post<ApiResponse<PaymentChannel[]>>('/payment.php', {
          type: 'onlinepay_list_v1',
          payType: 0,
        })
        .then(res => {
          const { status, info } = res.data
          if (status === 1) {
            setPaymentChannels(info)
          }
        })
    }
  }, [username])
}

export default usePaymentChannels