import { lazy,Route } from '@tanstack/router'

import type { FinancialTransactionTab } from '../../components/financial-transaction/tabs'
import { profileRoute } from '.'

const FinancialTransaction = lazy(
  () => import('../../pages/profile/financial-transaction'),
)

type FinancialTransactionSearch = {
  tab: FinancialTransactionTab
}

export const financialTransactionRoute = new Route({
  getParentRoute: () => profileRoute,
  path: 'financial-transaction',
  component: FinancialTransaction,
  validateSearch: (
    search: Record<string, unknown>,
  ): FinancialTransactionSearch => {
    return {
      tab:
        search.tab === 'all' ||
        search.tab === 'deposit' ||
        search.tab === 'withdrawals'
          ? search.tab
          : 'all',
    }
  },
})
