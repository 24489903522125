import type {LinariaClassName } from '@linaria/core'
import { cx } from '@linaria/core'
import { styled } from '@linaria/react'
import type { ReactNode } from 'react'

const StyledButton = styled.button`
  color: var(--secondary-btn-txt);
  line-height: 40px;
  border-radius: 6px;
  font-family: 'Poppins';
  text-align: center;
  font-size: 14px;
  display: block;
  text-decoration: underline;
  &:disabled {
    opacity: 0.5;
  }
  &.blue {
    color: var(--highlight);
  }
  &.green {
    color: var(--primary-btn-bg);
  }
  &.small {
    font-size: 12px;
    line-height: 38px;
  }
  &.large {
    font-size: 16px;
    line-height: 46px;
  }
  &.inline {
    line-height: normal;
    display: inline;
    text-align: left;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
    line-height: 42px;
    &.large {
      font-size: 18px;
      line-height: 50px;
    }
  }
`

interface TextButtonProps {
  variant?: 'blue' | 'green'
  size?: 'small' | 'large'
  children: ReactNode | string
  type?: 'submit' | 'button'
  onClick?: () => unknown
  className?: LinariaClassName
  inline?: boolean
}

const TextButton = ({
  variant,
  size,
  children,
  className,
  inline,
  ...rest
}: TextButtonProps) => {
  return (
    <StyledButton className={cx(variant, size, className, inline && 'inline')} {...rest}>
      {children}
    </StyledButton>
  )
}

export default TextButton
