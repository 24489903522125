import { redirect,Route } from '@tanstack/router'

import { rootRoute } from './root'

export const footerPageRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'footer/$page',
  component: () => null,
  beforeLoad: ({ match }) => {
    throw redirect({ to: '/help/$page', params: match.params })
  },
})
