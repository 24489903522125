import NiceModal from '@ebay/nice-modal-react'
import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import type { ChangeEvent} from 'react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { RegisterSearch } from '../../routes/register'
import type { ApiResponse } from '../../types/api'
import { api } from '../../utils/api-client'
import { getDeviceInfo } from '../../utils/device'
import { DefaultButton, Spinner } from '../common'
import Modal from '../common/modal'
import TextInput from './text-input'

const containerStyle = css`
  padding: 20px 20px 25px;
`

const buttonStyle = css`
  margin-top: 24px;
`

const Title = styled.h3`
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 12px;
  @media (min-width: 1200px) {
    font-size: 18px;
  }
`

const Desc = styled.p`
  font-family: 'Poppins';
  font-size: 14px;
  text-align: center;
  margin-bottom: 16px;
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

interface FillUsernameProps {
  onSubmit: (user: { username: string; promo_code: string }) => void
}

const FillUsername = ({ onSubmit }: FillUsernameProps) => {
  const adsInfo = JSON.parse(
    localStorage.getItem('ads_info') ?? '{}',
  ) as RegisterSearch
  const { t } = useTranslation('login')
  const [user, setUser] = useState({
    username: '',
    promo_code: '',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<{
    message: string
  }>({ message: '' })
  const [isValidating, setIsValidating] = useState(false)

  useEffect(() => {
    if (adsInfo.referral) {
      setUser(prev => ({ ...prev, promo_code: adsInfo.referral as string }))
    }
  }, [])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUser(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const validate = async () => {
    setIsValidating(true)
    try {
      const reqBody = {
        type: 'verify_parameters',
        username: user.username,
        meta: getDeviceInfo(),
      }
      const response = await api.post<ApiResponse<string>>(
        '/request.php',
        reqBody,
      )
      const { status, info } = response.data
      if (status === 0 && typeof info === 'string') {
        setError({
          message: info,
        })
        setIsValidating(false)
        return false
      }
      if (status === 1) {
        setError({
          message: '',
        })
        setIsValidating(false)
        return true
      }
    } catch (error) {
      console.error(error)
    }
    setIsValidating(false)
    return false
  }

  return (
    <Modal id="fill-username" containerStyle={containerStyle} zIndex={230}>
      <Title>Solo necesitamos unos cuantos detalles más...</Title>
      <Desc>
        Puedes crear un nombre de usuario y editarlo después en configuraciones.
        {!adsInfo.referral
          ? ' ¿Tienes un código de referido? Puedes agregarlo también.'
          : ''}
      </Desc>
      <TextInput
        variant="secondary"
        label={t('username', { ns: 'login' })}
        placeholder={'Eg:Raul678'}
        value={user.username}
        name="username"
        onChange={onChange}
        error={error.message}
        maxLength={16}
      />
      {!adsInfo.referral && (
        <TextInput
          variant="secondary"
          label="Código de Referido"
          placeholder={'Opcional'}
          value={user.promo_code}
          name="promo_code"
          onChange={onChange}
        />
      )}
      {isLoading || isValidating ? (
        <Spinner />
      ) : (
        <DefaultButton
          variant="blue"
          width={'100%'}
          className={buttonStyle}
          onClick={async () => {
            const valid = await validate()
            if (!valid) return
            setIsLoading(true)
            onSubmit(user)
          }}>
          Finalizar registro
        </DefaultButton>
      )}
    </Modal>
  )
}

export default NiceModal.create(FillUsername)
