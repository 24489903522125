import { styled } from '@linaria/react'
import { Portal } from 'react-portal'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 600;
  background-color: var(--primary-50);
  display: flex;
  align-items: center;
  justify-content: center;
  iframe {
    border: none;
    outline: none;
    width: 200px;
    height: 200px;
  }
  @media (min-width: 1200px) {
    iframe {
      width: 250px;
      height: 250px;
    }
  }
  &.solid-bg {
    background-color: var(--primary);
  }
`

interface LoadingProps {
  solidBg?: boolean
}

const Loading = ({ solidBg }: LoadingProps) => {
  return (
    <Portal>
      <Container className={solidBg ? 'solid-bg' : ''}>
        <iframe src="https://fun88mx.mx/loading.html" />
      </Container>
    </Portal>
  )
}

export default Loading
